/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from 'slugify'
import device from '../../utils/mq'
import VaweTwo from '../../assets/vaweTwo.svg'

const StyledSection = styled.section`
  padding-bottom: 180px;
  background-image: url(${(props) => (props.waveBackground ? VaweTwo : null)});
  background-size: cover;
  background-position: top;
  position: relative;
  top: 30px;

  @media ${device.tablet} {
    top: 25px;
  }

  @media (min-width: 830px) {
    top: 0px;
  }
`

StyledSection.defaultProps = {
  waveBackground: true,
}

const ImagesAndCtaWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  padding: 0 0 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 100px;
  max-width: 1300px;

  @media (min-width: 1210px) {
    padding: 0 90px 20px;
  }
`

const ImageAndCta = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;

  a {
    color: #fff;
    background-color: #5a55ab;
    padding: 12px 38px;
    font-weight: bold;
    font-style: normal;
    font-size: 15px;
    letter-spacing: 0.25px;
    line-height: 26px;
    border: 2px solid #5a55ab;
    border-radius: 100px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    width: auto;
    height: auto;
    box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;

    @media ${device.tablet} {
      font-size: 16px;
    }

    &:hover {
      color: #5a55ab;
      background-color: #fff;
      border-color: #fff;
    }
  }
`

const Image = styled.div`
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  justify-content: center;
  max-width: 172px;
`

const Subtitle = styled.h2`
  max-width: 800px;
  margin: 0 auto;
  padding: 105px 0 60px;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  @media ${device.tablet} {
    font-size: 32px;
  }
  @media ${device.laptopL} {
    padding-top: 105px;
  }
`

const ImagesAndCta = ({ waveBackground, headline, taIData }) => (
  <StyledSection waveBackground={waveBackground}>
    <Subtitle id={slugify(headline, { strict: true, lower: true })}>
      {headline}
    </Subtitle>
    <ImagesAndCtaWrapper>
      {taIData.map((item) => (
        <ImageAndCta>
          <Image>
            <GatsbyImage
              image={item?.image?.gatsbyImageData}
              alt=""
              imgStyle={{ objectFit: 'contain' }}
            />
          </Image>
          <a href={item.cta.link}>{item.cta.title}</a>
        </ImageAndCta>
      ))}
    </ImagesAndCtaWrapper>
  </StyledSection>
)
export default ImagesAndCta

export const fragment = graphql`
  fragment ImageAndCta on taIData {
    waveBackground
    id
    cta {
      title
      link
    }
    image {
      file {
        url
      }
      gatsbyImageData(width: 172, height: 172, quality: 90, layout: CONSTRAINED)
    }
  }
`

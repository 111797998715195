/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import device from '../../utils/mq'
//

const StyledSection = styled.section`
  padding: 45px 0;
  position: relative;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.bodyBg};
  text-align: center;
`

const Wrapper = styled.div`
  max-width: 1300px;
  padding: 0 50px;
  margin: 0 auto;
  @media ${device.tablet} {
    padding: 0 90px 0;
  }
`

const PartnersContainer = styled.div`
  max-width: 930px;
  position: relative;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`

const PartnerItem = styled.div`
  display: block;
  max-width: 180px;
  margin: 20px;
  justify-content: space-evenly;
  align-items: center;
  @media ${device.tablet} {
    margin: 25px 35px;
  }
`

const PartnerImg = styled.div`
  position: relative;
  justify-content: center;
  align-items: center;
  img {
    max-height: 100%;
    max-width: 50%;
    @media ${device.tablet} {
      max-width: 70%;
    }
  }
`

const Subtitle = styled.h2`
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 40px;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  @media ${device.tablet} {
    font-size: 44px;
  }
`

// eslint-disable-next-line prettier/prettier
const Partners = ({ headline, partnersData }) => (
  <StyledSection>
    <Wrapper>
      <Subtitle>{headline}</Subtitle>
      {/* <pre>{JSON.stringify(props, null, 2)}</pre> */}
      <PartnersContainer>
        {partnersData.map((item) => (
          <PartnerItem key={item?.details?.name}>
            <PartnerImg>
              <img
                src={item?.details?.image?.file?.url}
                alt={item?.details?.name}
              />
            </PartnerImg>
          </PartnerItem>
        ))}
      </PartnersContainer>
    </Wrapper>
  </StyledSection>
)

export default Partners

export const fragment = graphql`
  fragment PartnersSection on partnersData {
    details {
      name
      image {
        file {
          url
        }
      }
    }
  }
`

/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import device from '../../utils/mq'

const Header = styled.div`
  max-width: 1300px;
  padding: 50px 30px 0;
  margin: 0 auto;
  margin-bottom: 40px;
  max-width: 930px;
  text-align: center;
  @media ${device.tablet} {
    padding: 0 45px;
    margin-top: 100px;
  }
`

const HeroTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 50px;
  letter-spacing: -1.6px;
  line-height: 1.31;
  color: ${({ theme }) => theme.colors.brandPrimary};

  @media ${device.tablet} {
    font-size: 70px;
  }
`

const HeroSubline = styled.p`
  font-size: 18px;
  line-height: 1.45;
  margin: 0 auto 60px;
  color: ${({ theme }) => theme.colors.textColor};

  @media ${device.tablet} {
    font-size: 22px;
  }
`

const CtaWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 0 auto;

  a {
    color: #fff;
    background-color: #5a55ab;
    border-color: rgba(0, 0, 0, 0);
    padding: 10px 50px;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 0.25px;
    border-radius: 26px;
    border: 2px solid;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    width: auto;
    height: auto;
  }
`

const HeroLanding = ({ heroData }) => (
  <section>
    <Header>
      <HeroTitle>{heroData.headline}</HeroTitle>
      <HeroSubline>{heroData.subline}</HeroSubline>
      <CtaWrapper>
        <a
          href={heroData.callToAction.link}
          target="_blank"
          rel="noreferrer"
        >
          Get started
        </a>
      </CtaWrapper>
    </Header>
  </section>
)

export default HeroLanding

export const fragment = graphql`
  fragment HeroLanding on heroData {
    headline
    subline
    callToAction {
      link
    }
  }
`

/* eslint-disable react/prop-types */
import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import slugify from 'slugify'
import device from '../../utils/mq'
import VaweTwo from '../../assets/vaweTwo.svg'

const StyledSection = styled.section`
  background-image: url(${(props) => (props.waveBackground ? VaweTwo : null)});
  padding: 0px;
  background-position: top;
  background-size: 100%;
`

StyledSection.defaultProps = {
  waveBackground: false,
}

const Article = styled.article`
  width: 100%;
  margin: 0 auto;
  min-height: unset;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // &:not(:last-child) {
  //   margin: 0 auto 10vh;
  // }

  @media ${device.tablet} {
    min-height: 444px;
  }
  @media (min-width: 1210px) {
    min-height: 540px;
  }
  &:last-child {
    background-color: ${(props) =>
      props.imagePosition !== null
        ? '#FFFF'
        : props.waveBackground
        ? 'none'
        : '#f6f8fc'};
  }
`

const ArticleInner = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1300px;
  margin: 0 auto;
  padding: 0 10px;
  justify-content: ${(props) =>
    props.justifyContent === null ? 'center' : 'unset'};
  @media ${device.tablet} {
    flex-direction: ${(props) =>
      props.flexDirection ? props.flexDirection : 'row-reverse'};
  }
  @media ${device.mobileL} {
    padding: 0 15px;
  }
  @media ${device.tablet} {
    padding: 0 18px;
  }
  @media (min-width: 1210px) {
    padding: 0 160px;
  }
`

const ArticleText = styled.div`
  display: ${(props) => (props.display === null ? 'none' : 'flex')};
  flex-direction: column;
  flex: 45%;
  padding: 0 10px;
  align-self: center;
  margin-bottom: 30px;
  max-width: 525px;
  h2 {
    font-size: 26px;
    margin: 0 0 30px;
    color: #5a55ab;
    font-size: 900;
    text-align: center;
  }

  p {
    font-weight: 400;
    line-height: normal;
    margin: 0;
    font-size: 16px;
    text-align: center;
  }
  @media ${device.tablet} {
    h2 {
      font-size: 32px;
      line-height: 52px;
      text-align: left;
    }
    p {
      line-height: 26px;
      text-align: left;
    }
  }
`

const ArticleImage = styled.div`
  display: none;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 100%;
  padding-right: 15px;
  padding-left: 15px;
  justify-content: center;
  div[data-placeholder-image] {
    opacity: 0 !important;
  }
  img[data-main-image] {
    opacity: 1 !important;
  }

  @media ${device.tablet} {
    display: flex;
    margin-rigth: ${(props) => (props.imagePosition === null ? '0' : '-35px')};
    flex-basis: ${(props) =>
      props.imagePosition === null ? 'unset' : '58.33333%'};
    max-width: ${(props) =>
      props.imagePosition === null ? '700px' : '58.33333%'};
  }

  @media ${device.laptop} {
    display: flex;
    margin-rigth: ${(props) => (props.imagePosition === null ? '0' : '-35px')};
    flex-basis: ${(props) =>
      props.imagePosition === null ? 'unset' : '58.33333%'};
    max-width: ${(props) =>
      props.imagePosition === null ? '885px' : '58.33333%'};
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    overflow: hidden;
    text-align: right;
  }
`

const Subtitle = styled.h2`
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 100px;
  font-size: 28px;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  padding-top: 70px;
  @media ${device.tablet} {
    font-size: 32px;
    padding-top: ${(props) =>
      props.imagePosition === null && props.waveBackground ? '100px' : '10px'};
  }
  @media ${device.laptopL} {
    padding-top: ${(props) =>
      props.imagePosition === null && props.waveBackground ? '130px' : '10px'};
  }
`

const TextImageOnboarding = ({ waveBackground, headline, taIData }) => (
  <StyledSection waveBackground={waveBackground}>
    {taIData.map((item, index) => (
      <Article
        waveBackground={waveBackground}
        background={item.background}
        imagePosition={item.imagePosition}
        key={item.id}
      >
        {index === 0 ? (
          <Subtitle
            id={
              headline ? slugify(headline, { strict: true, lower: true }) : ''
            }
            imagePosition={item.imagePosition}
            waveBackground={waveBackground}
          >
            {headline}
          </Subtitle>
        ) : null}{' '}
        {}
        <ArticleInner
          flexDirection={item.imagePosition === true ? 'row-reverse' : 'row'}
          justifyContent={item.text}
          display={item.imagePosition}
        >
          <ArticleText display={item.text}>
            {/* <pre>{JSON.stringify(item, null, 2)}</pre> */}
            {item?.text?.headline && (
              <h2>{(item?.text?.headline).replace(/\u2028/g, '')}</h2>
            )}
            <p>{item?.text?.paragraph}</p>
            <p>{item.vaweOne}</p>
          </ArticleText>
          <ArticleImage imagePosition={item.imagePosition}>
            <GatsbyImage image={item?.image?.gatsbyImageData} alt="" />
          </ArticleImage>
        </ArticleInner>
      </Article>
    ))}
  </StyledSection>
)
export default TextImageOnboarding

export const fragment = graphql`
  fragment TextImageOnboarding on taIData {
    imagePosition
    id
    waveBackground
    text {
      headline
      paragraph
    }
    image {
      file {
        url
      }
      gatsbyImageData(width: 720, quality: 90, layout: CONSTRAINED)
    }
  }
`

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react'
import styled from 'styled-components'
import { GatsbyImage } from 'gatsby-plugin-image'
import { renderRichText } from 'gatsby-source-contentful/rich-text'
import device from '../../utils/mq'
import VaweOne from '../../assets/vaweOne.svg'

const StyledSection = styled.section`
  overflow-x: hidden;
  width: 100%;
  margin-top: -120px;
  margin-bottom: 50px;
  background-image: url(${VaweOne});
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  @media ${device.laptop} {
    margin-top: -180px;
  }
  @media (min-width: 1580px) {
    margin-top: -160px;
  }
`

const Hero = styled.div`
  max-width: 1300px;
  padding: 100px 20px;
  margin: 0 auto;
  margin-bottom: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: space-between;
    text-align: left;
    padding: 100px 50px;
  }

  a {
    color: #fff;
    background-color: #5a55ab;
    padding: 10px 30px;
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 0.25px;
    border: 2px solid #5a55ab;
    border-radius: 26px;
    white-space: nowrap;
    cursor: pointer;
    display: inline-block;
    width: auto;
    height: auto;
    margin-top: 20px;
    margin-right: 0;
    box-shadow: 0 25px 30px -10px rgba(90, 85, 171, 0.6);
    transition: background-color 0.2s ease-out, border-color 0.2s ease-out;
    @media ${device.mobileL} {
      font-size: 13px;
      padding: 10px 11px;
      margin-right: 15px;
    }
    @media ${device.tablet} {
      font-size: 15px;
      padding: 10px 20px;
    }
    @media (min-width: 1210px) {
      font-size: 16px;
      padding: 10px 30px;
    }

    &:hover {
      color: #5a55ab;
      background-color: #fff;
      border-color: #fff;
    }

    &:first-child {
      color: #5a55ab;
      background-color: #eeedf6;
      margin-right: 0;

      &:hover {
        color: #fff;
        background-color: #5a55ab;
        border-color: #5a55ab;
      }
    }
  }
`

const HeroTitle = styled.h1`
  margin: 0 0 30px;
  font-size: 30px;
  color: #5a55ab;
  max-width: 600px;
  font-stretch: normal;
  letter-spacing: normal;
  flex-basis: 55.5%;
  @media ${device.mobileL} {
    font-size: 32px;
    line-height: 1.5;
  }

  @media ${device.tablet} {
    font-size: 48px;
    line-height: 1.5;
  }
  @media (min-width: 1210px) {
    font-size: 50px;
    line-height: 1.5;
  }
`

const HeroText = styled.div`
  align-self: center;
  margin-bottom: 80px;
  @media (min-width: 1024px) {
    padding-top: 120px;
    min-width: 554px;
    margin-right: 20px;
  }
`

const HeroImage = styled.div`
  display: none;
  flex-basis: 44.5%;
  padding-top: 20px;
  padding-bottom: 60px;
  justify-content: center;
  div[data-placeholder-image] {
    opacity: 0 !important;
  }
  img[data-main-image] {
    opacity: 1 !important;
  }

  @media (min-width: 1024px) {
    display: flex;
    padding-top: 100px;
    padding-bottom: 0;
  }
  /* @media ${device.tablet} {
    display: flex;
    margin-left: -40px;
    align-self: center;
  }
  @media (min-width: 1024px) {
    div {
      min-width: 650px;
    }
  }
  @media (min-width: 1225px) {
    div {
      min-width: 750px;
    }
  }
  @media (min-width: 1324px) {
    div {
      min-width: 775px;
    }
  }
  @media (min-width: 1524px) {
    div {
      min-width: 950px;
    }
  } */
`
const HeroOnboarding = ({ heroData }) => (
  <StyledSection>
    <Hero>
      {/* <pre>{JSON.stringify(heroData, null, 2)}</pre> */}
      <HeroText>
        <HeroTitle>{heroData?.headline}</HeroTitle>
        {heroData.cta1 && (
          <a href={heroData.cta1.link} rel="noreferrer">
            {heroData.cta1.title}
          </a>
        )}
        {heroData.cta2 && (
          <a href={heroData.cta2.link}>{heroData.cta2.title}</a>
        )}
      </HeroText>
      <HeroImage>
        <GatsbyImage
          image={heroData?.image?.gatsbyImageData}
          alt="Hero image"
          objectFit="contain"
          // style={{ maxWidth: `600px` }}
        />
      </HeroImage>
    </Hero>
  </StyledSection>
)
export default HeroOnboarding

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react'
import HeroLanding from '../components/sections/HeroLanding'
import HeroOnboarding from '../components/sections/HeroOnboarding'
import TextImage from '../components/sections/TextImage'
import TextImageOnboarding from '../components/sections/TextImageOnboarding'
import Testimonial from '../components/sections/Testimonial'
import Partners from '../components/sections/Partners'
import ImagesAndCta from '../components/sections/ImagesAndCta'
import Cta from '../components/sections/Cta'
import SEO from '../components/Seo'

const Solution = ({ pageContext: { data, name, description } }) => (
  <>
    <SEO title={name} description={description} />
    {data.map((section) => {
      const { typeName } = section

      switch (typeName) {
        case '_Hero':
          return <HeroLanding key={typeName} {...section} />

        case '_HeroOnboarding':
          return <HeroOnboarding key={typeName} {...section} />

        case '_Partners':
          return <Partners key={typeName} {...section} />

        case '_ImagesAndCta':
          return <ImagesAndCta key={typeName} {...section} />

        case '_TextAndImage':
          return <TextImage key={typeName} {...section} />

        case '_TextAndImageOnboarding':
          return <TextImageOnboarding key={typeName} {...section} />

        case '_Testimonial':
          return <Testimonial key={typeName} {...section} />

        case '_CTA':
          return <Cta key={typeName} {...section} />

        default:
          return null
      }
    })}
  </>
)

export default Solution
